/*  */

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

/*  */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* .lockPanel {
  position: absolute;
  width: 100%;
  height: 200vh;
  top: 0%;
  left: 0%;
  z-index: 9999;
  text-align: center;
  color: #fff;
  background: #242020a8;
  padding: 15px;
  border-radius: 15px;
  box-shadow: rgb(121, 110, 177) 6px 11px 41px -28px;
  padding-top: 250px;
} */

#websiteImageBox::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#websiteImageBox::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

#websiteImageBox::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #2bb3e5;
}


#question_images::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#question_images::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

#question_images::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #2bb3e5;
}

/*  */

.react-tel-input .form-control{
  width: 250px !important;
}

.react-tel-input .country-list{
  width: 250px !important;
}

#custom_phone_input .form-control{
  padding-left: 45px !important;
  width: -webkit-fill-available !important;
}

@media print {
  .iq-sidebar,.iq-top-navbar {
    display: none;
  }
}

/* Custom Suggestion box */

.searchTabsArea {
  padding: 10px;
  /* border-top: 1px solid #e6e9ec; */
  margin-top: 10px;
  position: absolute;
  background: #fff;
  width: 91%;
  top: 30px;
  border-radius: 10px;
  left: 13px;
  z-index: 9;
   -webkit-box-shadow: 0 16px 32px 0 rgba(7, 28, 31, 0.1)
  
}
.searchTabsArea ul { margin: 0; padding: 0; list-style: none; }
.searchTabsArea ul li{ margin-bottom:0px; }
.searchTabsArea ul li a{ padding: 5px; color: #000; font-size: 14px; line-height: 16px; display: block; }
.searchTabsArea ul li a img{
 
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 4px 5px 16px rgba(64,68,77,.12);
}
/* .searchOverlay{overflow: hidden;}
.searchOverlay {
  position: fixed;
  z-index: 2;
  width: 100%;
  height: calc(100vh - 56px);
  top: 56px;
  left: 0;
  background: rgba(246,248,251,.25);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  animation: hm-hero-search-fade-in .15s ease-in;
}
.searchOverlay h2{-webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  animation: hm-hero-search-fade-in .15s ease-in;} */
  /* End */

  .customReportTime{
    background: #fff;
    color: #000;
    border: 1px solid #f0f0f0;
    font-size: 15px;
    padding: 3px 12px !important;
    border-radius: 50px;
    white-space: nowrap;
}

.customDatePicker {
  position: relative; /* Ensure the date picker is positioned relative to its container */
  z-index: 9999; /* Set a high z-index value to make the date picker appear above other elements */
}
